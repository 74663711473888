<template>
  <div class="mobileSwitchParam">
    <mHead :name="'智能开关'" :add="'/mobile/ProSwitch'" :adds="'/mobile/ProSwitchParam'" :color="false" />
    <div class="param">
      <div class="title">相关参数</div>

      <img src="../../assets/mobile/product/switch10.png" class="img" />

      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">LS2032A</div>
      </div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">86×86×13mm</div>
      </div>

      <div class="text">
        <div class="left">额定电压:</div>
        <div class="right">3V</div>
      </div>
      <div class="text">
        <div class="left">电池类型:</div>
        <div class="right">CR2032</div>
      </div>

      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>

      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
      <div class="text">
        <div class="left">通信协议:</div>
        <div class="right">自定义LSSN2.0</div>
      </div>
      <div class="text">
        <div class="left">工作频段:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>
      <div class="text">
        <div class="left">待机电流:</div>
        <div class="right">＜2uA</div>
      </div>
      <div class="text">
        <div class="left">无线发射功率:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div
        class="txt"
      >智能开关是客控系统中控制光源的主要设备，提供了四个按键：两个独立的灯光开关和两个灯光场景开关，且均可定制，每个按键均有配备独立可调光的背光灯。开关通过自研的，具有低延迟、强抗干扰能力的协议与技术连接到主机。当连接主机时，可以通过主机独立控制每个灯的开关，以及控制更多灯光联动与场景。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.mobileSwitchParam {
  background-color: #fff;
  overflow: hidden;
  .param {
    margin: 0px 0.3rem;
    padding-bottom: 0.26rem;
    border-bottom: 1px solid #333;
    .title {
      margin-top: 0.8rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #FF9000;
    }
    .img {
      margin-top: 0.4rem;
      margin-left: 0.1rem;
      width: 100%;
    }

    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0.6rem;
      }
      .left {
        width: 1.56rem;
        text-align: right;
      }
      .right {
        margin-left: 0.6rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0px 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
   .data {
    width:6.45rem;
    margin-top: 0.39rem;
    padding-bottom: 0.8rem;
    display: flex;
    
    .dataImg{
        width: 100%;
        height: 0.18rem;
        
    }
  }
  }
}
</style>
